<template>
  <CCol sm="12">
    <dataTableExtended
      class="align-center-row-datatable"
      :items="computedList"
      :fields="fields"
      :items-per-page="5"
      column-filter
      pagination
      :table-filter="tableText.tableFilterText"
      :items-per-page-select="tableText.itemsPerPageText"
      :noItemsView="tableText.noItemsViewText"
      sorter
    >
      <template #Quantity="{ item }">
        <td class="text-center">
          {{ formatNumber(item.Quantity, 2) }}
        </td>
      </template>
      <template #FirstEventDate="{ item }">
        <td class="text-center">
          {{ formatOnlyDateWithSlash(item?.YardBlDetailJson?.[0]?.FirstEventDate) }}
        </td>
      </template>
      <template #LastEventDate="{ item }">
        <td class="text-center">
          {{ formatOnlyDateWithSlash(item?.YardBlDetailJson?.[0]?.LastEventDate) }}
        </td>
      </template>
      <template #options="{ item }">
        <td class="text-center">
          <CButton 
            square
            size="sm"
            color="watch"
            v-c-tooltip="{
              content: `${$t('label.see')} ${$t('label.yard')}`,
              placement: 'top-start'
            }"
            @click="SeeUbicationDetailJson(item)"
          >
            <CIcon name='cil-list' />
          </CButton>
        </td>
      </template>
      <template #details="{item}">
        <CCollapse v-if="item?.YardBlDetailJson?.[0]?.UbicationDetailJson && item.YardBlDetailJson?.[0]?.UbicationDetailJson.length != 0" :show="Boolean(item?.FgCollapse)" class="p-2">
          <dataTableExtended
            class="align-center-row-datatable"
            column-filter
            :items="computedUbicationDetailJson(item?.YardBlDetailJson?.[0]?.UbicationDetailJson)"
            :fields="fieldUbicationDetail"
            :table-filter="tableText.tableFilterText"
            :noItemsView="tableText.noItemsViewText"
            :items-per-page="tableText.itemsPerPage"
          >
            <template #Quantity="{ item }">
              <td class="text-center">
                {{ formatNumber(item?.Quantity, 2) }}
              </td>
            </template>
          </dataTableExtended>
        </CCollapse>
      </template>
    </dataTableExtended>
  </CCol>
</template>
<script>

import General from '@/_mixins/general';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';

//data
function data() {
  return {
    
  };
}

//methods
function formatNumber(number, decimal) {
  return NumberFormater.formatNumber(number, decimal);
}

function formatOnlyDateWithSlash(date) {
  return date ? DateFormater.formatOnlyDateWithSlash(date) : 'N/A';
}

function SeeUbicationDetailJson(item) {
  this.$store.state.yardManagement.dataBl.UbicationJson[item.Nro-1].FgCollapse = !item?.FgCollapse;
  this.$store.state.yardManagement.dataBl.UbicationJson = [...this.Bl.UbicationJson];
}

function computedUbicationDetailJson(UbicationDetailJson = []) {
  return UbicationDetailJson.map((item) => {
    return {
      ...item
    }
  })
}

//computeds
function computedList() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return this.Bl?.UbicationJson?.map((item) => {
    let YardBlDetailJson = item?.YardBlDetailJson?.[0] ?? {};
    return {
      ...item,
      PackagingName: item[`PackagingName${_lang}`] ?? '',
      YardName: YardBlDetailJson?.YardName ?? 'N/A',
      Quantity: YardBlDetailJson?.Quantity ?? 0,
      DaysInYard: YardBlDetailJson?.DaysInYard ?? 0,
      FirstEventName: YardBlDetailJson?.[`FirstEventName${_lang}`] ?? '',
      LastEventName: YardBlDetailJson?.[`LastEventName${_lang}`] ?? '',
    };
  });
}

function fields(){
  return [
    { key: 'options', label: '', _style: 'width: 1%; min-width:45px; max-width:45px;', sorter: false, filter: false },
    { label: '#', key: 'Nro', _classes: 'text-center', _style: 'min-width: 45px;', filter: false},
    { label: this.$t('label.yard'), key: 'YardName',_style: 'min-width: 200px;',_classes: 'text-uppercase text-center'},
    { label: this.$t('label.packaging'), key: 'PackagingName',_style: 'min-width: 180px;',_classes: 'text-uppercase text-center'},
    { label: this.$t('label.quantity'), key: 'Quantity',  _classes: 'text-uppercase text-center', _style: 'min-width: 140px', },
    { label: `${this.$t('label.Day')}S`, key: 'DaysInYard', _classes: 'text-center text-uppercase', _style:'min-width: 140px' },
    { label: this.$t('label.FirstEvent'), key: 'FirstEventName',_classes:'text-center', _style:'min-width: 180px' },
    { label: this.$t('label.FirstEventDate'), key: 'FirstEventDate',_classes:'text-center', _style:'min-width: 160px' },
    { label: this.$t('label.LastEvent'), key: 'LastEventName',_classes:'text-center', _style:'min-width: 180px' },
    { label: this.$t('label.LastEventDate'), key: 'LastEventDate',_classes:'text-center', _style:'min-width: 160px' },
  ];
}

function fieldUbicationDetail(){
  return [
    { key: 'YardAreaName', label: this.$t('label.module'), _classes: 'text-uppercase text-center', _style: 'width: 50%'},
    { key: 'Quantity', label: this.$t('label.quantity'), _classes: 'text-uppercase text-center', _style: 'width: 50%'},
  ];
}

export default{
  name: 'currentSituation',
  data,
  mixins: [General],
  props: {
    Bl: {
      type: Object,
      default: undefined,
    },
  },
  methods: {
    formatNumber,
    formatOnlyDateWithSlash,
    SeeUbicationDetailJson,
    computedUbicationDetailJson,
  },
  computed: {
    computedList,
    fields,
    fieldUbicationDetail,
  },
  watch: {
    Bl: function (NewVal) {
      if (NewVal && Object.keys(NewVal).length != 0) {
        this.$store.state.yardManagement.dataBl?.UbicationJson?.map((e, index) => {
          e.Nro = index+1;
          e.FgCollapse = true;
        })
      }
    }
  }
}
</script>